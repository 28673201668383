import { Label, Dialog as TableDialog } from '@primer/react';
import { DataTable } from '@primer/react/drafts';
import { Alert, ClassesRow, Dialog, GroupedButtons, StudentsRow, Table, TeachersRow } from 'components';
import { AlertProps } from 'components/alert';
import { CoreContext } from 'contexts';
import { headers } from 'data';
import { Layout } from 'layouts';
import { PageHeader } from 'layouts/header';
import { IClass, ISchool, ISubject, ITeacher } from 'pages/schools/models/index.models';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { SelectSubjects } from 'pages/class';
import { toast } from 'react-toastify';
import { coreService, studentsService, teachersService } from 'services';
import { IStudent } from 'services/students/index.models';
import './index.css';
import { Fees } from './sections/fees';
import { Onboarding } from './sections/onboarding';

const School = () => {
  const params = useParams();
  const { user, role, setCurriculum, activeCurriculum, showLineLoader, setShowLineLoader, loading } =
    useContext(CoreContext);
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [showAlert, setShowAlert] = useState<AlertProps>({
    show: false,
    type: 'success',
    title: 'Success',
    message: 'This is a success alert.'
  });
  const [activeTab, setActiveTab] = useState('classes');
  const [school, setSchool] = useState<ISchool | null>(null);
  const [teachers, setTeachers] = useState<ITeacher[]>([]);
  const [students, setStudents] = useState<IStudent[]>([]);
  const [classes, setClasses] = useState<IClass[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState<ITeacher | null>(null);
  const returnFocusRef = useRef(null);
  const [classIsStreamed, setClassIsStreamed] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    count: 0,
    pages: 0,
    size: 0
  });
  const [paginate, setPaginate] = useState({
    page: 1,
    size: 10
  });
  const [selectedClass, setSelectedClass] = useState<IClass | null>(null);
  const [editClass, setEditClass] = useState(false);
  const [subjects, setSubjects] = useState<ISubject[]>([]);
  const [updatingClass, setUpdatingClass] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const schoolId = params.id ?? user?.uid ?? '';
        setShowLineLoader(true);

        if (params.id && role !== 'admin') {
          navigate('/schools');
        } else {
          const res = await coreService.getSchoolById(schoolId);
          if (res) {
            setShowLineLoader(false);
            setClasses(res.classes ?? []);
            setSchool(res);
            setCurriculum(res.curriculums[0]);
          } else {
            navigate('/schools');
          }
        }
      } catch (error) {
        if (error instanceof Error) toast.error(error.message);
        setShowLineLoader(false);
      }
    })();
  }, [params, navigate, user, role, setCurriculum, setShowLineLoader]);

  const getSchoolStudents = async (paginate: { page: number; size: number }) => {
    try {
      await studentsService.getSchoolStudents(school?.id ?? '', paginate, res => {
        setStudents(res.data);
        setPagination(res.pagination);
        setPaginate(paginate);
      });
    } catch (error) {
      if (error instanceof Error) toast.error(error.message);
    }
  };

  const handleCreateClass = async (event: any) => {
    event.preventDefault();
    try {
      const { grade, streamed, stream } = event.target.elements;
      const selectedClass = activeCurriculum?.classes?.find(item => item.name === grade.value);

      if (!selectedClass) return;

      const streamedClass: { stream?: string } = {};
      if (stream?.value && streamed.value === 'yes') streamedClass.stream = stream.value;

      await coreService.createClass({ ...selectedClass, ...streamedClass, school_id: school?.id ?? '' }, res => {
        setShowDialog(false);

        setShowAlert({
          show: true,
          type: 'success',
          title: 'Success',
          message: res.message
        });
      });
      await coreService.getClassesBySchoolId(school?.id ?? '', res => {
        setClasses(res);
      });
      event.target.reset();
    } catch (error) {
      if (error instanceof Error) toast.error(error.message);
    }
  };

  return (
    <Layout
      back={{
        label: 'Back to schools',
        link: '/schools'
      }}
      className="school"
    >
      <Alert
        show={showAlert.show}
        title={showAlert.title}
        message={showAlert.message}
        type={showAlert.type}
        onClose={() => setShowAlert({ ...showAlert, show: false })}
      />
      <section className="school-content">
        <PageHeader
          onAddClick={() => setShowDialog(true)}
          actionButtonLabel="Add Class"
          disableActionButton={activeTab === 'teachers' || activeTab === 'students'}
          title={school?.name}
          logo={school?.photo_url}
          description="Manage teachers, students and add classes, manage fees and more."
          loading={loading}
        />
        <section className="header-ctas">
          <GroupedButtons
            clickHandler={async tab => {
              setActiveTab(tab);
              try {
                switch (tab) {
                  case 'classes':
                    await coreService.getClassesBySchoolId(school?.id ?? '', res => {
                      setClasses(res);
                    });
                    break;
                  case 'teachers':
                    await teachersService.getTeachers(
                      {
                        school_id: school?.id ?? ''
                      },
                      teachers => {
                        setTeachers(teachers);
                      }
                    );
                    break;
                  case 'students':
                    await getSchoolStudents({ page: 1, size: paginate.size });
                    break;
                  case 'fees':
                    break;
                  default:
                    break;
                }
              } catch (error) {
                if (error instanceof Error) {
                  setShowAlert({
                    show: true,
                    type: 'error',
                    title: 'Error',
                    message: error.message
                  });
                }
              }
            }}
            buttons={[
              {
                label: 'Classes'
              },
              {
                label: 'Teachers'
              },
              {
                label: 'Students'
              },
              {
                label: 'Fees'
              },
              {
                label: 'Onboarding'
              }
            ]}
          />
        </section>
        {activeTab === 'classes' ? (
          <Table
            itemsLabel="Classes"
            header={headers.classes}
            data={classes.map(item => (
              <ClassesRow
                item={item}
                school_id={school?.id ?? ''}
                key={item.id}
                role={role}
                setClasses={setClasses}
                setEditClass={() => {
                  setSelectedClass(item);
                  setEditClass(true);
                  setSubjects(item.subjects);
                }}
              />
            ))}
          />
        ) : activeTab === 'teachers' ? (
          <Table
            itemsLabel="Teachers"
            emptyText={showLineLoader ? 'Please wait...' : 'No teachers yet'}
            header={headers.teachers}
            data={teachers.map((teacher, i) => (
              <TeachersRow
                onSelect={(selected: any) => {
                  setSelectedTeacher(selected);
                  setIsOpen(true);
                }}
                item={teacher}
                key={teacher.id + i}
              />
            ))}
          />
        ) : activeTab === 'students' ? (
          <Table
            itemsLabel="Students"
            totalItems={pagination.count}
            header={headers.students}
            emptyText={showLineLoader ? 'Please wait...' : 'No students yet'}
            pagination={{
              show: true,
              options: {
                sizes: [10, 20, 25, 50, 100],
                pages: pagination.pages,
                currentPage: paginate.page,
                handlePageChange: async data => {
                  setPaginate({ ...paginate, page: data.page });
                  await getSchoolStudents({ page: data.page, size: data.size });
                }
              }
            }}
            data={students.map(item => (
              <StudentsRow item={item} key={item.id} />
            ))}
          />
        ) : null}
        {activeTab === 'fees' && <Fees school_id={school?.id ?? ''} />}
        {activeTab === 'onboarding' && <Onboarding school_id={school?.id ?? ''} />}
        <Dialog
          showDialog={showDialog}
          onClose={() => setShowDialog(false)}
          title="Add New Class"
          dialogContent={
            <section>
              <form className="form" onSubmit={handleCreateClass}>
                <div className="form-group">
                  <label htmlFor="grade" className="form-label">
                    Class
                  </label>
                  <select name="grade" className="form-input" id="grade" required>
                    <option value="">Select</option>
                    {activeCurriculum?.classes?.map(item => (
                      <option value={item.name} key={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="streamed" className="form-label">
                    Streamed
                  </label>
                  <select
                    name="streamed"
                    className="form-input"
                    id="streamed"
                    defaultValue="no"
                    onChange={e => setClassIsStreamed(e.target.value === 'yes' ? true : false)}
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                {classIsStreamed && (
                  <div className="form-group">
                    <label htmlFor="stream" className="form-label">
                      Stream
                    </label>
                    <input name="stream" className="form-input" type="text" placeholder="e.g Yellow" required />
                  </div>
                )}
                <button type="submit" className="button" disabled={showLineLoader}>
                  Add Class
                </button>
              </form>
            </section>
          }
        />
        <TableDialog
          sx={{
            width: '100%',
            maxWidth: 600
          }}
          returnFocusRef={returnFocusRef}
          isOpen={isOpen}
          onDismiss={() => setIsOpen(false)}
          aria-labelledby="header"
        >
          <div data-testid="inner">
            <TableDialog.Header id="header">Teacher Classes</TableDialog.Header>
            <div style={{ padding: 16 }}>
              <DataTable
                aria-labelledby="classes"
                aria-describedby="classes"
                data={selectedTeacher?.classes ?? []}
                columns={[
                  {
                    header: 'Class',
                    field: 'name',
                    rowHeader: true
                  },
                  {
                    header: 'Subjects',
                    field: 'subjects',
                    renderCell: row =>
                      row.subjects.map((subject: string, i) => (
                        <Label
                          style={{
                            marginRight: 4
                          }}
                          key={subject + i}
                        >
                          {subject}
                        </Label>
                      ))
                  }
                ]}
              />
            </div>
          </div>
        </TableDialog>
        {editClass && selectedClass && (
          <Dialog
            floated={true}
            title="Edit Class"
            onClose={() => {
              setEditClass(false);
              setSelectedClass(null);
              setSubjects([]);
            }}
            showDialog={true}
            dialogContent={
              <form
                className="form"
                onSubmit={async (event: any) => {
                  event.preventDefault();
                  try {
                    const { name, stream } = event.target.elements;
                    const data: {
                      school_id: string;
                      name?: string;
                      stream?: string;
                      subjects?: ISubject[];
                    } = {
                      school_id: selectedClass.school_id,
                      name: name?.value,
                      stream: stream?.value
                    };
                    setUpdatingClass(true);
                    if (subjects.length) data.subjects = subjects;
                    await coreService.updateClass({ id: selectedClass.id, data }, res => toast.success(res.message));
                    await coreService.getClassesBySchoolId(school?.id ?? '', res => {
                      setClasses(res);
                    });
                    setUpdatingClass(false);
                  } catch (error) {
                    if (error instanceof Error) toast.error(error.message);
                    setUpdatingClass(false);
                  }
                }}
              >
                <div className="form-group">
                  <label htmlFor="name" className="form-label">
                    Class Name
                  </label>
                  <input
                    id="name"
                    disabled={true}
                    name="name"
                    className="form-input"
                    type="text"
                    required={true}
                    defaultValue={selectedClass?.name}
                  />
                </div>
                {selectedClass.streamed && (
                  <div className="form-group">
                    <label htmlFor="stream" className="form-label">
                      Stream Name
                    </label>
                    <input
                      id="stream"
                      name="stream"
                      className="form-input"
                      type="text"
                      required={true}
                      defaultValue={selectedClass.stream}
                    />
                  </div>
                )}
                <SelectSubjects list={selectedClass?.subjects ?? []} subjects={subjects} setSubjects={setSubjects} />
                <button type="submit" className="button" disabled={updatingClass}>
                  Save Changes
                </button>
              </form>
            }
          />
        )}
      </section>
    </Layout>
  );
};

export default School;
