import { ThemeProvider } from '@primer/react';
import { CoreContext } from 'contexts';
import { Auth, Calendar, Home, ManageHome, NewsLetters, NotFound, School, SchoolClass, Schools, Student } from 'pages';
import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import { RotatingLines } from 'react-loader-spinner';
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { AuthProvider } from './contexts';
import './index.css';
import reportWebVitals from './reportWebVitals';

const PrivateRoute = () => {
  const { user, loading } = useContext(CoreContext);
  return loading ? (
    <section className="loader">
      <RotatingLines strokeColor="#ddd" />
      <div className="loader-text">
        <p>Recess {new Date().getFullYear()}</p>
      </div>
    </section>
  ) : user ? (
    <Outlet context={user} />
  ) : (
    <Navigate to="/auth" />
  );
};

const StyledErrorElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  h1,
  p {
    color: palevioletred;
  }

  h1 {
    font-size: 2rem;
    font-weight: 600;
    color: #333;
  }

  p {
    font-size: 1.2rem;
    font-weight: 400;
  }
`;

const ErrorElement = () => {
  return (
    <StyledErrorElement>
      <h1>Oops! Something went wrong.</h1>
      <p>Please try again later as we work to fix it!</p>
    </StyledErrorElement>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<Home />} errorElement={<ErrorElement />} />
        <Route path="/classes" element={<School />} errorElement={<ErrorElement />} />
        <Route path="/newsletters" element={<NewsLetters />} errorElement={<ErrorElement />} />
        <Route path="/calendar" element={<Calendar />} errorElement={<ErrorElement />} />
        <Route path="/classes/:id" element={<SchoolClass />} errorElement={<ErrorElement />} />
        <Route path="/schools" element={<Schools />} errorElement={<ErrorElement />} />
        <Route path="/schools/:id" element={<School />} errorElement={<ErrorElement />} />
        <Route path="/schools/:id/:id" element={<SchoolClass />} errorElement={<ErrorElement />} />
        <Route path="/manage" element={<ManageHome />} errorElement={<ErrorElement />} />
        <Route path="/students/:id" element={<Student />} errorElement={<ErrorElement />} />
      </Route>
      <Route path="/auth" element={<Auth />} errorElement={<ErrorElement />} />
      <Route path="*" element={<NotFound />} errorElement={<ErrorElement />} />
    </>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ThemeProvider>
        <ToastContainer />
        <RouterProvider router={router} />
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();

// serviceWorker.register({
//   loadInDevMode: true
// });
