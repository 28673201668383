import { ArrowUpRightIcon, DownloadIcon, UploadIcon } from '@primer/octicons-react';
import { Button, Flash, TabNav } from '@primer/react';
import { TabNavSecondary, Table } from 'components';
import { GroupedFilters } from 'components/filters';
import { CoreContext } from 'contexts';
import { formatISO } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { coreService, resultsService } from 'services';
import { ExamStatus } from 'services/exams/index.models';
import { StyledResults } from './components/results';
import { ResultsSummary } from './components/summary';
import { EExamView, IRankingPieData, IRankingStudent } from './index.model';
import { RankingView, ResultType } from 'services/results/index.model';

export const Results = ({
  subjects,
  payload
}: {
  subjects: {
    id: string;
    name: string;
  }[];
  payload: {
    class_id: string;
    school_id: string;
    class_name: string;
  };
}) => {
  const { setShowAlert, setShowLineLoader, role, examList } = useContext(CoreContext);
  const navigate = useNavigate();
  const [generatingTemplate, setGeneratingTemplate] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [exams, setExams] = useState<
    {
      id: string;
      name: string;
      total_marks: number;
      subject_id: string;
    }[]
  >([
    {
      id: 'select',
      name: 'Select Exam',
      total_marks: 0,
      subject_id: ''
    }
  ]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedExam, setSelectedExam] = useState({
    id: '',
    name: '',
    total_marks: 0,
    subject_id: ''
  });
  const [disableMarks, setDisableMarks] = useState(true);
  const [activeTab, setActiveTab] = useState<EExamView>(EExamView.Upload);
  const [resultsView, setResultsView] = useState<'class' | 'summary'>('class');
  const [studnetsRanking, setStudentsRanking] = useState<IRankingStudent[]>([]);
  const [resultsPieData, setResultsPieData] = useState<IRankingPieData[]>([]);
  const [resultType, setResultType] = useState<ResultType>(ResultType.Graded);

  useEffect(() => {
    if (examList.length && payload) {
      setExams(
        examList
          .filter(item => item.status === ExamStatus.Active && item.class.id === payload.class_id)
          .map(exam => ({
            id: exam.id,
            name: exam.name,
            total_marks: exam.total_marks ?? 0,
            subject_id: exam.subject.id
          }))
      );
    }
  }, [examList, payload]);

  const handleBulkUpload = async (event: any) => {
    event.preventDefault();
    try {
      setUploading(true);
      const { subject, term, file, exam, date, marks, type } = event.target;
      await coreService.uploadFiles([file.files[0]], `${payload.school_id}`, `results`, '', async res => {
        try {
          if (!res.uploading) {
            await resultsService.uploadResults(
              {
                file_name: res.files[0].name,
                class_id: payload.class_id,
                school_id: payload.school_id,
                term: term.value,
                subject: subject.value,
                exam_id: exam.value,
                exam_date: formatISO(date.value),
                total_marks: marks?.value ?? selectedExam.total_marks,
                type: type.value
              },
              res => {
                setUploading(false);
                setShowAlert({
                  message: 'Results uploaded successfully. Please come back later to view the results.',
                  show: true,
                  type: 'success',
                  title: `Uploaded - (${res.count})`
                });
                event.target.reset();
              }
            );
          }
        } catch (error) {
          if (error instanceof Error) toast.error(error.message);
          setUploading(false);
          setShowLineLoader(false);
        }
      });
    } catch (error: any) {
      setShowAlert({
        message: error?.message ?? 'An error occurred',
        show: true,
        type: 'error',
        title: 'Error'
      });
      setGeneratingTemplate(false);
    }
  };

  const handleExportTemplate = async (event: any) => {
    event.preventDefault();
    try {
      setGeneratingTemplate(true);
      await resultsService.exportResultsTemplate(
        {
          school_id: payload.school_id,
          class_id: payload.class_id,
          type: resultType
        },
        data => {
          coreService.generateFileFromData(
            data,
            `${payload.class_name.split(' ').join('_')}-${new Date().getFullYear()}-results-template-${resultType}.csv`,
            'text/csv'
          );
          setGeneratingTemplate(false);
        }
      );
    } catch (error) {
      if (error instanceof Error) toast.error(error.message ?? 'An error occurred');
      setGeneratingTemplate(false);
    }
  };

  return (
    <StyledResults>
      <TabNav className="results-header-tabs" aria-label="Main">
        {[
          {
            id: EExamView.Upload,
            label: 'Upload Results'
          },
          {
            id: EExamView.View,
            label: 'View Results'
          }
        ].map(tab => (
          <TabNav.Link
            onClick={async () => {
              try {
                setActiveTab(tab.id);
                if (tab.id === EExamView.View) {
                  setResultsView('class');
                  setShowLineLoader(true);
                  await resultsService.getStudentsResultsRanking(
                    {
                      school_id: payload.school_id,
                      class_id: payload.class_id,
                      view: RankingView.Students,
                      direction: 'desc',
                      year: new Date().getFullYear().toString()
                    },
                    res => setStudentsRanking(res)
                  );
                  setShowLineLoader(false);
                }
              } catch (error) {
                if (error instanceof Error) toast.error(error.message);
                setShowLineLoader(false);
              }
            }}
            key={tab.id}
            selected={activeTab === tab.id}
            className={activeTab === tab.id ? 'tab-active' : ''}
            sx={{
              color: activeTab === tab.id ? '#1cbc96' : '#000',
              fontWeight: activeTab === tab.id ? 'bold' : 'normal',
              cursor: 'pointer'
            }}
          >
            {tab.label}
          </TabNav.Link>
        ))}
      </TabNav>
      {activeTab === EExamView.Upload ? (
        <section className="results-upload">
          <div className="results-export">
            <div className="results-title">Results</div>
            <div className="results-card">
              <div className="results-description">
                <p>
                  Please download the template below and populate it with the results of the students in the class.
                  Ensure that;
                </p>
                <ol>
                  <li>
                    The file should be in{' '}
                    <b>
                      <i>CSV</i> format.
                    </b>
                  </li>
                  <li>
                    All number fields should be in <b>number</b> format for e.g score, remark score, etc.
                    <b>0</b> or <b>100</b>.
                  </li>
                </ol>
              </div>

              <div className="form-group">
                <label htmlFor="firstUpload" className="form-label">
                  Select result type
                </label>
                <select
                  name="resultType"
                  id="resultType"
                  className="form-input"
                  required
                  defaultValue={ResultType.Graded}
                  onChange={e => setResultType(e.target.value as any)}
                >
                  {[
                    {
                      id: 'graded',
                      name: 'Graded'
                    },
                    {
                      id: 'naration',
                      name: 'Naration'
                    }
                  ].map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="results-export-btns">
                <Button trailingVisual={DownloadIcon} disabled={generatingTemplate} onClick={handleExportTemplate}>
                  Download Template
                </Button>
              </div>
            </div>
          </div>
          <form onSubmit={handleBulkUpload}>
            <Flash
              variant="warning"
              style={{
                padding: '8px 10px',
                fontSize: 13,
                marginBottom: 10,
                display: exams.length ? 'none' : 'block'
              }}
            >
              {role === 'school' ? (
                <>
                  Your school does not have exams list setup, please proceed to <Link to={'/manage'}>manage page</Link>{' '}
                  to setup.
                </>
              ) : (
                'Exam list is empty, please contact your school admin to setup exams'
              )}
            </Flash>
            <div className="form-group">
              <label htmlFor="subject" className="form-label">
                Select Subject
              </label>
              <select
                name="subject"
                id="subject"
                className="form-input"
                required
                onChange={event => setSelectedSubject(event.target.value)}
              >
                {[
                  {
                    id: 'select',
                    name: 'Select Subject'
                  },
                  ...subjects
                ].map(subject => (
                  <option key={subject.id} value={subject.id}>
                    {subject.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="exam" className="form-label">
                Select Exam
              </label>
              <select
                name="exam"
                id="exam"
                className="form-input"
                required
                onChange={event => {
                  event.preventDefault();
                  const exam = exams.find(item => item.id === event.target.value);
                  if (exam) {
                    setSelectedExam(exam);
                    setDisableMarks(exam.total_marks > 0);
                  }
                }}
              >
                {[
                  {
                    id: 'select-exam',
                    name: 'Select Exam',
                    total_marks: 0,
                    subject_id: ''
                  },
                  ...exams.filter(item => item.subject_id === selectedSubject)
                ].map(exam => (
                  <option key={exam.id} value={exam.id}>
                    {exam.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="firstUpload" className="form-label">
                Select result type
              </label>
              <select
                name="type"
                id="type"
                className="form-input"
                required
                defaultValue={ResultType.Graded}
                onChange={e => setResultType(e.target.value as any)}
              >
                {[
                  {
                    id: 'graded',
                    name: 'Graded'
                  },
                  {
                    id: 'naration',
                    name: 'Naration'
                  }
                ].map(item => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            {resultType === ResultType.Graded && (
              <div className="form-group">
                <label
                  style={{
                    display: 'flex',
                    gap: 5,
                    alignItems: 'center'
                  }}
                  htmlFor="marks"
                  className="form-label"
                >
                  <span>Total Marks (Click the checkbox to set total marks) </span>
                  <input
                    style={{ width: 'auto' }}
                    type="checkbox"
                    className="form-input"
                    onChange={event => setDisableMarks(!event.target.checked)}
                  />
                </label>
                {disableMarks ? (
                  <input
                    type="number"
                    max="100"
                    disabled={disableMarks}
                    className="form-input"
                    placeholder={`${selectedExam.total_marks ?? 0}`}
                  />
                ) : (
                  <input
                    type="number"
                    max="100"
                    name="marks"
                    id="marks"
                    className="form-input"
                    required
                    placeholder={`${selectedExam.total_marks ?? 0}`}
                  />
                )}
              </div>
            )}
            <div className="form-group">
              <label htmlFor="title" className="form-label">
                Select Term
              </label>
              <select name="term" id="term" className="form-input" required>
                {coreService.getTerms().map(term => (
                  <option key={term.id} value={term.id}>
                    {term.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="date" className="form-label">
                Exam Date
              </label>
              <input
                type="date"
                name="date"
                id="date"
                className="form-input"
                max={new Date().toISOString().split('T')[0]}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="file" className="form-label">
                Upload Template
              </label>
              <input type="file" name="file" id="file" className="form-input" accept=".csv" required />
            </div>
            <div className="form-group-btns">
              <Button trailingVisual={UploadIcon} disabled={uploading || !exams.length} type="submit">
                {uploading ? 'Please wait...' : 'Upload Results'}
              </Button>
            </div>
          </form>
        </section>
      ) : (
        <section className="results-listing">
          <div className="results-listing-title">Year - {new Date().getFullYear()}</div>
          <TabNavSecondary
            data={[
              {
                label: 'Class Results',
                value: 'class'
              },
              {
                label: 'Summary Results',
                value: 'summary'
              }
            ]}
            onSelect={async value => {
              try {
                setResultsView(value as 'class' | 'summary');
                if (payload.school_id && payload.class_id) {
                  setShowLineLoader(true);
                  switch (value) {
                    case 'class':
                      await resultsService.getStudentsResultsRanking(
                        {
                          school_id: payload.school_id,
                          class_id: payload.class_id,
                          view: RankingView.Students,
                          direction: 'desc',
                          year: new Date().getFullYear().toString()
                        },
                        res => setStudentsRanking(res)
                      );
                      setShowLineLoader(false);
                      break;
                    case 'summary':
                      await resultsService.getStudentsResultsRanking(
                        {
                          school_id: payload.school_id,
                          class_id: payload.class_id,
                          view: RankingView.Summary,
                          year: new Date().getFullYear().toString()
                        },
                        res => setResultsPieData(res)
                      );
                      setShowLineLoader(false);
                      break;
                    default:
                      break;
                  }
                }
              } catch (error) {
                if (error instanceof Error) toast.error(error.message);
              }
            }}
          />
          {resultsView === 'class' ? (
            <section className="results-listing-class">
              <GroupedFilters
                filterGroups={[
                  {
                    label: 'Gender',
                    id: 'gender',
                    defaultValue: 'all',
                    options: [
                      {
                        label: 'All',
                        value: 'all'
                      },
                      {
                        label: 'Male',
                        value: 'male'
                      },
                      {
                        label: 'Female',
                        value: 'female'
                      },
                      {
                        label: 'Other',
                        value: 'other'
                      }
                    ]
                  },
                  {
                    label: 'Term',
                    id: 'term',
                    defaultValue: 'all',
                    options: [
                      {
                        label: 'All',
                        value: 'all'
                      },
                      {
                        label: 'First Term',
                        value: '1'
                      },
                      {
                        label: 'Second Term',
                        value: '2'
                      },
                      {
                        label: 'Third Term',
                        value: '3'
                      }
                    ]
                  },
                  {
                    label: 'Subject',
                    id: 'subject',
                    defaultValue: 'all',
                    options: [
                      {
                        label: 'All',
                        value: 'all'
                      },
                      ...subjects.map(subject => ({
                        label: subject.name,
                        value: subject.id
                      }))
                    ]
                  },
                  {
                    label: 'Direction',
                    id: 'direction',
                    defaultValue: 'desc',
                    options: [
                      {
                        label: 'Descending',
                        value: 'desc'
                      },
                      {
                        label: 'Ascending',
                        value: 'asc'
                      }
                    ]
                  }
                ]}
                onChange={async filters => {
                  try {
                    const filtered = Object.keys(filters).reduce((acc, key) => {
                      if (filters[key] !== 'all') acc[key] = filters[key];
                      return acc;
                    }, {} as { [key: string]: string });
                    let view = RankingView.Students;
                    if (filtered.gender) {
                      view =
                        filtered.gender === 'male'
                          ? RankingView.Male
                          : filtered.gender === 'female'
                          ? RankingView.Female
                          : RankingView.Other;
                    }
                    setShowLineLoader(true);
                    await resultsService.getStudentsResultsRanking(
                      {
                        view,
                        school_id: payload.school_id,
                        class_id: payload.class_id,
                        direction: filters.direction as 'desc' | 'asc',
                        year: new Date().getFullYear().toString(),
                        term: filters.term,
                        subject: filters.subject
                      },
                      res => setStudentsRanking(res)
                    );
                    setShowLineLoader(false);
                  } catch (error) {
                    if (error instanceof Error) toast.error(error.message);
                  }
                }}
              />
              <Table
                showCount={false}
                header={[
                  {
                    label: 'Class Rank'
                  },
                  {
                    label: 'Stream Rank'
                  },
                  {
                    label: 'Admission No'
                  },
                  {
                    label: 'Name'
                  },
                  {
                    label: 'Gender'
                  },
                  {
                    label: 'Total Score'
                  },
                  {
                    label: 'Average Score'
                  },
                  {
                    label: 'Actions'
                  }
                ]}
                data={studnetsRanking.map(student => (
                  <tr key={student.admission_no}>
                    <td>{student.rank}</td>
                    <td>{student.stream_rank}</td>
                    <td>{student.admission_no}</td>
                    <td>{`${student.first_name} ${student.last_name}`}</td>
                    <td>{student.gender}</td>
                    <td>{student.total_score}</td>
                    <td>{student.average_score}</td>
                    <td>
                      <Button
                        trailingVisual={ArrowUpRightIcon}
                        size="small"
                        onClick={() => navigate(`/students/${student.id}`)}
                      >
                        More
                      </Button>
                    </td>
                  </tr>
                ))}
              />
            </section>
          ) : (
            <section className="results-listing-summary">
              <ResultsSummary data={resultsPieData} />
            </section>
          )}
        </section>
      )}
    </StyledResults>
  );
};
